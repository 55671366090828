@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');


body {
  background-color: #f3f3f3 !important;
}

.row {
  --bs-gutter-x: 0rem !important;
}


.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 0rem !important;
}


@import "abstracts/variables";
@import "base/global";
@import "base/reset";
@import "base/typography";
@import "~bootstrap/scss/bootstrap";
@import "components/header";
@import "components/banner";
@import "components/marquee";
@import "components/loader";
@import "components/middle";
@import "components/footer";
@import "components/neo";
@import "components/luxury";



.viluxur-m-top-header {
  margin-top: 80px;
}



.slide-pane__overlay {
  z-index: 99999;
}

.slide-pane__content {
  padding: 40px 80px !important;
  background-color: #f3f3f3;

  @media (max-width: $layout-breakpoint-medium) {
    padding: 20px 30px !important;
  }

}

.slide-pane__header {
  background: #f3f3f3 !important;
  border-bottom: 0px !important
}





//5s-0f-luxury page css

.luxury-m-titile {
  margin-left: 20%;
  margin-top: -10px;

  @media (max-width: $layout-breakpoint-medium) {
    margin-left: 30%;
    margin-top: -10px;

  }

}

.title-2 {
  font-size: 6rem;
  font-weight: 600;
  margin-bottom: 50px;

  @media (max-width: $layout-breakpoint-medium) {
    font-size: 4rem;
    margin-bottom: 35px;
  }

  @media (max-width: $layout-breakpoint-small) {
    font-size: 3rem;
    margin-bottom: 25px;
  }
}

.header-content {

  width: 650px;

  @media (max-width: $layout-breakpoint-medium) {
    width: 470px;


  }

  @media (max-width: $layout-breakpoint-small) {
    margin-top: 40%;

    margin-left: 0%;
    width: 100%;
    padding: 0px 30px;
  }
}

.m-wav-top {
  margin-top: -13%;

  @media (max-width: $layout-breakpoint-medium) {
    margin-top: -20%;


  }
}

.margin-top-and-botm {
  margin-top: 12%;
  margin-bottom: 8%;
}

.pdn-50-left-r {
  padding: 0px 40px !important;

  @media (max-width: $layout-breakpoint-medium) {
    padding: 0px 20px !important;


  }
}

.padin-20 {
  padding: 10px !important;
  margin-top: 20px;

  @media (max-width: $layout-breakpoint-medium) {
    padding: 10px !important;


  }
}

.pdn-around-viluxur {
  padding: 50px 100px !important;


  @media (max-width: $layout-breakpoint-medium) {
    padding: 10px 70px !important;


  }
}

.always-viluxur-qut {
  margin-bottom: 20%;
  text-align: right;
  margin-left: 137px;
  padding: 0px 100px;

  @media (max-width: $layout-breakpoint-medium) {
    margin-left: 37px;
    margin-bottom: 0%;



  }

}

.flex-colm-around {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mt-5 {
  margin-top: 40px;
}

.container-fluid.margin-top-and-botm.mb-0-important {
  margin-bottom: 0px !important;
}

.mt-01 {
  margin-top: 0px !important;
}

.css-1by7580-MuiMasonry-root {
  margin: calc(0px - (0px / 2)) !important;
}

.row-title-gray {
  margin-left: 30px;
  margin-right: 30px;

}

// .banner-inner .row-title-gray .row-letter{
//   font-size: 25rem!important;
//   line-height: 0em!important;

//   @media (max-width: $layout-breakpoint-medium) {
//     font-size: 23rem!important;
//     line-height: 0em!important;

//   }


// }


.top-botom-m {
  margin-bottom: 8%;

  @media (max-width: $layout-breakpoint-medium) {
    margin-top: 6%;


  }

}

.width-middle-sec {
  width: 65%;
  margin: 0 auto;

  @media (max-width: $layout-breakpoint-small) {
    width: 90%;


  }
}

.banner-inner .row-title .row-letter {
  font-size: 19rem !important;

  @media (max-width: $layout-breakpoint-medium) {
    font-size: 12rem !important;


  }
}


@media (min-width: 1400px) {
  .container.gallery {
    max-width: 1460px;
  }
}

.mt-8 {
  margin-top: 10%;

  @media (max-width: $layout-breakpoint-small) {
    margin-top: 15%;


  }
}

.row-title-3 {
  margin-left: 30px;
}

.banner-inner .row-title-3 .row-letter {
  font-size: 19rem !important;

  @media (max-width: $layout-breakpoint-medium) {
    font-size: 12rem !important;


  }
}

.col-12 .MuiBox-root.css-b7eh40-MuiMasonry-root {
  margin: calc(0px - (0px / 2)) !important;
}

.p-lr-30 {
  padding-left: 35px;

}

.css-1qmhgm0 {
  margin: 0px !important;
}

.mobile-top-m {
  margin-top: 22%;
}


.mobile-top-m-b {
  margin-top: 10%;
  margin-bottom: 15%;
}

.img-carsol {
  width: 200px !important;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 200px !important;

  @media (max-width: $layout-breakpoint-small) {
    margin-bottom: 250px !important;


  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-indicators {
  display: none !important;
}

span.carousel-control-prev-icon:before {
  content: '';
  background-color: #8e8e8e;
  width: 50px;
  height: 50px;
  position: absolute;
  display: block;
  z-index: -1;
  border-radius: 50%;

  @media (max-width: $layout-breakpoint-small) {
    width: 35px;
    height: 35px;


  }
}

span.carousel-control-next-icon:after {
  content: '';
  background-color: #8e8e8e;
  width: 50px;
  height: 50px;
  position: absolute;
  display: block;
  z-index: -1;
  border-radius: 50%;

  @media (max-width: $layout-breakpoint-small) {
    width: 35px;
    height: 35px;


  }
}

.we-got-you-4-mobile {
  padding: 0px 30px;
}

.mobile-slider-img {
  width: 300px;
  margin: 0 auto;
  margin-bottom: 265px;
}

.m-top-10 {
  margin-top: -13%;
}


.curve-font {
  font-size: 28px;
  margin-bottom: 0px;

  @media (max-width: $layout-breakpoint-medium) {
    font-size: 24px;
  }

  @media (max-width: $layout-breakpoint-small) {
    font-size: 22px;
  }
}

.ds-post {
  font-size: 16px;
  font-weight: 500;

  @media (max-width: $layout-breakpoint-medium) {
    font-size: 15px;
  }

  @media (max-width: $layout-breakpoint-small) {
    font-size: 15px;
  }
}



.float-right {
  margin-top: 23px;
  width: 200px;
  float: right;
}

.footer-form-mobile .slide-pane_from_right {
  @media (max-width: $layout-breakpoint-small) {
    width: 92% !important;


  }
}

.footer-link {
  text-decoration: none;
  color: #000;
}

.footer-link:hover {
  color: #fff;
}

.carrer-page-email {
  margin-top: 50px;
}

.carrer-page-email a {
  color: #3d4248;
  text-decoration: none;

}

.carrer-page-email.title-2 {
  @media (max-width: $layout-breakpoint-small) {
    font-size: 2rem;
    margin-bottom: 0px;
  }
}

.signature {
  margin-top: 90px;

  @media (max-width: $layout-breakpoint-small) {
    margin-top: 40px;
  }
}

.nav {
  display: none !important;
}

.gray-text-footer {
  font-family: "Bembo Regular";
  font-style: italic;
  color: rgb(200, 200, 200);
  font-weight: 300 !important;
}


.page-top-space {
  margin-top: 3%;
}

.heading-top {}

.we-are-viluxur {
  margin-top: -23%;
  ;
  margin-left: 9%;

  @media (max-width: $layout-breakpoint-medium) {
    margin-top: -13%;
  }
}

.alind-end {
  align-items: flex-end;
}

.mrgn-mins-top {
  margin-top: -24%;

  @media (max-width: $layout-breakpoint-medium) {
    margin-top: -31%;
  }

  @media (max-width: $layout-breakpoint-small) {
    margin-top: 5%;
  }
}

.z-index-min {
  z-index: -88;
}

.we-got-you-2.the-end-goal {
  width: 820px;
  margin-left: 34%;

  @media (max-width: $layout-breakpoint-medium) {
    width: 588px;
    margin-left: 42%;
  }
}

.qute-icon-m-top {
  margin-top: 34px;
}

.aword-carasol .carousel-caption {


  bottom: 5.25rem !important;
  left: 1% !important;
  text-align: left !important;

  @media (max-width: $layout-breakpoint-small) {
    display: none !important;
  }


}

.width-600 {
  width: 600px;

  @media (max-width: $layout-breakpoint-medium) {
    width: 450px;
  }
}

.signature.min-signature {
  margin-top: 65px !important;

  @media (max-width: $layout-breakpoint-small) {
    margin-top: 35px !important;
  }
}

.mb-10-new {
  margin-bottom: 30px;
}

.mrgn-mins-top-prep {
  margin-top: -15%;
}

.local-carasol .carousel-caption {

  bottom: 49.25rem;
  left: 60%;
  color: #1d1b1b;
  text-align: left;

  @media (max-width: $layout-breakpoint-medium) {
    bottom: 31.25rem;
    left: 56%;
  }

  @media (max-width: $layout-breakpoint-small) {
    display: none;
  }

}

.mtop-mins {
  margin-top: -120px !important;
}

a {
  color: inherit !important;
  text-decoration: none;
  font-weight: 400;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit !important;
  text-decoration: none !important;
  font-weight: 400;
}

.banner-row.marquee.animate {
  @media (max-width: $layout-breakpoint-small) {
    margin-bottom: -8px;
  }

}

.logo-row-images .row-title img {
  width: 200px !important;

  @media (max-width: $layout-breakpoint-small) {
    width: 135px !important;
  }
}

.page-space-top-mobile {
  margin-top: 10%;
}

.margin-min-and-zindex {
  margin-top: -75px;
}

.we-are-viluxur-page .transition-image.final {
  width: 100% !important;
}

.zindex-min {
  z-index: -8;
}

.we-are-viluxur-page .mobile-logos.banner {
  margin-top: 15px;
}

.arrow-righ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

}

.widh-90 {
  width: 90%;
  margin: 0 auto;
}

.team-mobile .we-got-you {
  margin-top: 15%;
  margin-bottom: 15%;
}

.team-mobile .carousel-caption {
  top: 48% !important;
  bottom: 0px;
  left: 0%;
  right: 0%;
  padding-top: 3.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  padding-left: 30px;
  text-align: center;
  padding-right: 30px;
}

.padding-r-l-30 {
  padding: 0px 30px;
  margin-bottom: 50px;
}

.meet-team-carasol-img {
  margin: 0 auto;
  width: 271px;
}

.meet-the-team-carasol .carousel-inner {
  overflow: visible;
}

.meet-the-team-carasol .carousel-caption {
  top: 100% !important;
}

.viluxur-top-image-margin {
  margin-top: 75%;
}

.min-zindex {
  z-index: -8888;
}

.page-space-top-mobile.we-are-viluxur-page {
  @media (max-width: $layout-breakpoint-small) {
    margin-top: 20% !important;
  }
}

.margin-top-100 {
  margin-top: 70px;
}

.road-trip-caption-title {
  font-size: 24px;
  color: #000;
}

.road-map-carosol .d-block {
  margin: 0 auto;
}

.road-map-carosol .carousel-caption {
  top: 105%;
}

.road-map-carosol .carousel-indicators {
  display: flex !important;
  top: 110%;
  margin: 0 auto;

  @media (max-width: $layout-breakpoint-small) {
    top: 169%;
  }
}

.carousel-indicators [data-bs-target] {
  background-color: #a5a5a5;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.carousel-indicators .button {
  margin: 0 auto;
}

.road-map-carosol .carousel-inner {
  overflow: visible;
}


.road-map-carosol {
  margin-top: 7%;
  margin-bottom: 8%;

  @media (max-width: $layout-breakpoint-medium) {
    margin-top: 3%;
    margin-bottom: 10%;
  }
}

.road-map-carosol .carousel-control-prev,
.road-map-carosol .carousel-control-next {
  display: none;
}

.rotate-left {
  transform: rotate(180deg);
  left: -68px;
  top: 44px;
}

.we-got-you-2.left-future {
  margin-left: 43%;
}

.future-second .mrgn-mins-top {
  margin-top: -1% !important;
}

.mobile-we-are-prepard {
  margin-top: 43%;

  @media (max-width: $layout-breakpoint-small) {
    margin-top: 38%;
  }
}


.margn-top-20 {
  margin-top: 26% !important;
}

.para-m-botom {
  margin-bottom: 5px !important;
}

.viluxur-top-image-margin-2 {
  margin-top: 50%;
}


.road-map-carosol.web-carasol .carousel-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.road-map-carosol.web-carasol .carousel-item .carousel-caption {
  position: static;
}

.ve-are-viluxur-top-page {
  margin-top: 3%;
}

.we-are-future .we-got-you-2.the-end-goal {
  margin-left: 47%;
  width: 600px !important;
}

.mb-5-new {
  margin-bottom: 5%;
}

.mb-5-new .we-got-you-4 {
  margin-top: 10%;
}

.pdn-30 {
  padding: 30px !important;
}

.bg-white {
  background-color: #f3f3f3 !important;
}

.pdn-30-form {
  padding: 50px !important;

  @media (max-width: $layout-breakpoint-medium) {
    padding: 30px !important;
  }

  @media (max-width: $layout-breakpoint-small) {
    padding: 70px 30px !important;
  }
}

.pdn-lr-30 {
  padding: 0px 30px;
}

.rebuild.team-mobile .we-got-you {

  @media (max-width: $layout-breakpoint-medium) {
    margin-bottom: 26%;
  }


}

.mb-3 {
  @media (max-width: $layout-breakpoint-medium) {
    margin-bottom: 15% !important;
  }
}


.whatsapp_float {

  @media (max-width: $layout-breakpoint-small) {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 20px;
    left: 20px;
    background-color: #d2ba8e;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px rgb(153 153 153 / 14%);
    z-index: 100;

  }
}

.whatsapp_float .whatsapp-icon {
  color: #fff;
  margin-top: 15px;
}


.mr-right-form {
  margin-right: 30px;
}

.flex-align-center {
  display: flex;

}

.para-form {
  font-size: 1.8rem;

  @media (max-width: $layout-breakpoint-small) {
    font-size: 1.4rem;
  }
}

.para-form.mb-20 {
  margin-bottom: 40px;
}

.mt-50 {
  margin-top: 100px;
}

.outline-none {
  outline: none !important;
}


.display-inline-row {
  margin-left: 20px !important;
  margin-top: 10px !important;
  display: flex !important;
  flex-direction: row !important;
}

.display-inline-row span svg {
  width: 2em !important;
  height: 2em !important;
}

.display-inline-row .form-checkpoint span {
  font-size: 18px !important;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  color: rgb(63, 60, 60) !important;

  @media (max-width: $layout-breakpoint-small) {
    font-size: 1.4rem !important;
  }
}

.display-inline-row .css-j204z7-MuiFormControlLabel-root {
  margin-right: 45px !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #c9b081 !important;
}

.row.input-style.mt-50 {
  @media (max-width: $layout-breakpoint-small) {
    margin-top: 30px;
  }


}

.container-video {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.video {
  position: absolute;
  width: auto;
  height: 100%;
  top: 0;
  left: 0;
}

.mt-12 {
  margin-top: 12%;
}

.pl-0 {
  padding-left: 0 !important;
}

.padin-30 {
  padding: 0px 20px;
}

.heading-3 {
  font-size: 4rem;
  font-weight: 600;
  margin-bottom: 50px;
}

.service-pdn-img {
  padding: 30px !important;
}

.service-m-top {
  margin-top: 60px;
}

.service-pdn-img-inner {
  padding: 20px;
}

.m-min-10 {
  margin-top: -15%;
}

.react-multi-carousel-item {
  padding: 0px 25px !important;

}

.m-min-top-vip {
  margin-top: -5%;
}


ul.our_servis {
  list-style: outside;
  padding-left: 56px !important;

  @media (max-width: $layout-breakpoint-small) {
    padding-left: 30px !important;
  }
}

ul.our_servis li {
  margin-bottom: 20px;
}

.border_btm li {
  border-bottom: 1px solid #e1dcdc;
  padding-bottom: 20px;
  margin-bottom: 40px !important;
}

.border_btm {
  width: 85%;
  margin: 0 auto;
}

.pdig-ex-30 {
  padding: 0px 30px;
}


.react-multiple-carousel__arrow {
  width: 40px !important;
  height: 40px !important;
  background: rgb(165 157 157 / 50%) !important;
  padding: 19px !important;
}

.custom {
  .heading-3 {
    margin-bottom: 20px !important;
  }
}

.custom-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 76px;
}

.grid-item {
  width: 360px;
  height: auto;
}

.grid-item img {
  width: 100%;
  height: auto;
  display: block;
}

@media (max-width: 768px) {
  .custom-row {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media (max-width: 480px) {
  .custom-row {
    grid-template-columns: 1fr;
  }
}


.form-small {
  font-size: 1.4rem;
}

.support-options {
  margin-top: 10px;

  label {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.3rem;
    color: hsl(222, 20%, 20%);
    margin-bottom: 10px;
  }

  input {
    padding: 7px;
    border: 1px solid black;
    width: 7px;
    height: 7px;
  }

  input[type="radio"]:checked {
    border-color: #c9b081;
    /* Blue border for checked state */
    background-color: #c9b081;
    /* Blue background for checked state */
  }
}

input.other-market-input {
  width: 100%;
  height: 100%;
  font-size: 14px;
  border: 1px solid #a19f9f !important;
}

.modal-dialog {
  .modal-body {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 0px;
  }

  p.para.text-center {
    font-size: 1.5rem;
    max-width: 80%;
    margin: auto;
    margin-bottom: 50px;
  }

  h2.modal-title.title-2.text-center {
    font-size: 3rem;
    margin-bottom: 10px;
  }

  span {
    font-size: 3rem;
  }

  .input-style {
    margin: 25px 0px 0px 0px;
  }

  button.close {
    padding: 30px 30px 10px 10px;
    background: none;
    border: none;
    font-size: 25px;
    height: 40px;
    margin-left: auto;
  }

  form {
    background: none;
  }

  p.para-form {
    font-size: 1.4rem;
    margin-bottom: 0.1rem;
  }

  input[type=text] {
    padding: 7px 0px;
    height: 35px;
    font-size: 1.5rem;
  }

  input[type="number"] {
    padding: 7px 0px;
    height: 35px;
    font-size: 1.5rem;
  }

  textarea {
    min-height: 7rem;
  }

  .row.input-style .submit-btn {
    width: 175px;
    margin-right: auto;
    margin-left: auto !important;
    margin-top: 20px;
  }

  .modal-header {
    padding: 0px;
    border: 0px;
  }

  .other-market-input input[type="text"] {
    width: 100%;
    border: 1px solid #a19f9f;
    padding-left: 10px;
  }

  .other-market-input {
    margin-left: 0px;
    margin-top: 10px;
  }
}

.page-top-space.we-are-future {
  margin-top: 0px;
  height: 80vh;
  background-image: url(/images/VH-Web-Banner.jpg);
  background-size: cover;
  background-position: center;
}