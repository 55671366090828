:root {
  --h: 222;
  --s: 20%;
  --light: #f3f3f3;
  --dark: hsl(var(--h), var(--s), 20%);
  --tint: #c9b081;
  --tint2: #c9b081;
  --pads: max(1vw, 10px);
}

.header {
  font-size: 1.8rem;
  height: 156px;
  display: flex;
  justify-content: center;
  padding: 0 108px;
  color: $black;

  @media (max-width: $layout-breakpoint-medium) {
    padding: 0 55px;
    font-size: 1.6rem;
    height: 116px;
  }

  @media (max-width: $layout-breakpoint-xsmall) {
    padding: 0 12px;
    height: 96px;
    font-size: 1.2rem;
  }

  .header-inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      text-decoration: none !important;
    }

    .logo {
      font-weight: 400;
      font-size: 3.4rem !important;
      color: #3a3834;
      text-decoration: none !important;

      @media (max-width: $layout-breakpoint-medium) {
        font-size: 2.8rem;
        flex: 3;
      }
    }

    .nav {

      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      li {
        list-style: none;
        margin: 0 40px;

        @media (max-width: $layout-breakpoint-medium) {
          margin: 0 25px;
        }

        a {
          text-decoration: none;
          color: $black;
          white-space: nowrap;
          cursor: pointer;
        }
      }
    }

    .contact {
      display: flex;
      flex-direction: row;
      gap: 20px;
      @media (max-width: $layout-breakpoint-medium) {
        flex: 1;
        text-align: right;
      }

      a:last-child {
        color: $black;
        text-decoration: none;
        border-bottom: 2px solid $black;
        padding-bottom: 12px;
        white-space: nowrap;

        @media (max-width: $layout-breakpoint-xsmall) {
          border: none;
        }
      }

      .whatsapp-main-wrapper{
        display: flex;
        flex-direction: row;
        gap: 10px;
      }
    }

    .hamburger-menu {
      height: 56px;
      width: 56px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
      cursor: pointer;

      &:hover {
        background: darken($color: $white, $amount: 12);
      }

      @media (min-width: $layout-breakpoint-medium) {
        display: none;
      }

      span {
        width: 20px;
        height: 3px;
        margin: 2px 0;
        background: $black;
        display: block;
      }
    }
  }
}


a.flip-animate {
  perspective: 1000px;

  span {
    position: relative;
    display: inline-block;
    padding: 0;
    transition: transform 0.3s;
    transform-origin: 50% 0;
    transform-style: preserve-3d;
  }

  span:before {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    content: attr(data-hover);
    transition: color 0.3s;
    transform: rotateX(-90deg);
    transform-origin: 50% 0;
    text-align: center;
  }

  &:hover span,
  &:focus span {
    transform: rotateX(90deg) translateY(-22px);
  }

  &:hover span:before,
  &:focus span:before {
    color: #c3aa78;
  }
}

.flip-animate span {
  font-size: 1.8rem;
}

.hover-event {
  cursor: pointer !important;
}

.header-title {
  font-size: 6rem;
  font-weight: 600;
  margin-bottom: 50px;

  @media (max-width: $layout-breakpoint-medium) {
    font-size: 4rem;
    margin-bottom: 25px;
  }
}

.para-text {
  font-size: 1.8rem;

  @media (max-width: $layout-breakpoint-medium) {
    font-size: 1.4rem;
  }
}



//form style

input,
select,
button,
textarea {
  font-family: inherit;
  color: var(--fg);
  padding: 0.875em;
  width: clamp(50%, 20em, 100%);
  border: none;
  accent-color: var(--tint2);
  width: 100%;
  height: 60px;
  font-size: 18px;
  background: transparent;
  border-bottom: 1px solid #a19f9f;

  @media (max-width: $layout-breakpoint-medium) {
    height: 50px;
    font-size: 16px;
  }

  @media (max-width: $layout-breakpoint-small) {
    height: 30px;
    font-size: 16px;
  }


  &::placeholder {
    opacity: 0.33;
    font-style: italic;
  }
}

textarea {
  width: 100%;
  min-height: 10em;
  resize: vertical;
  display: block;
  border: 1px solid #a19f9f !important;
}

select {
  appearance: none;
  background-image: url('data:image/svg+xml; utf8, <svg width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.2929,5.292875 C12.6834,4.902375 13.3166,4.902375 13.7071,5.292875 C14.0976,5.683375 14.0976,6.316555 13.7071,6.707085 L8.70711,11.707085 C8.31658,12.097605 7.68342,12.097605 7.29289,11.707085 L2.29289,6.707085 C1.90237,6.316555 1.90237,5.683375 2.29289,5.292875 C2.68342,4.902375 3.31658,4.902375 3.70711,5.292875 L8,9.585765 L12.2929,5.292875 Z"  style="fill: hsl(222,20%,40%);"/></svg>');
  background-size: 1em;
  background-position: calc(100% - 1em) 50%;
  background-repeat: no-repeat;

  .dark & {
    background-image: url('data:image/svg+xml; utf8, <svg width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.2929,5.292875 C12.6834,4.902375 13.3166,4.902375 13.7071,5.292875 C14.0976,5.683375 14.0976,6.316555 13.7071,6.707085 L8.70711,11.707085 C8.31658,12.097605 7.68342,12.097605 7.29289,11.707085 L2.29289,6.707085 C1.90237,6.316555 1.90237,5.683375 2.29289,5.292875 C2.68342,4.902375 3.31658,4.902375 3.70711,5.292875 L8,9.585765 L12.2929,5.292875 Z"  style="fill: hsl(222,20%,70%);"/></svg>');
  }
}

input[type="radio"],
input[type="checkbox"] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 0;
  width: calc(1em + 8px);
  height: calc(1em + 8px);
  border: none;
  outline: none;
  appearance: none;
  border-radius: 100%;

  &,
  &:after {
    transition: all 0.6s ease-in;
  }

  &:focus,
  &:focus:after {
    transition: all 0.3s ease-out;
  }

  &:after {
    content: "";
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    background: transparent;
    border-radius: 100%;
    opacity: 0.9;
  }

  &:checked {
    &:after {
      background: var(--fg);
    }
  }

  &:focus {
    background-color: var(--tint);

    &:after {
      opacity: 1;
    }
  }

  &+label {
    text-transform: none;
    display: inline-flex;
    padding: 0 0.25em;
    user-select: none;
    transition: none;
  }

  &:checked+label {
    font-weight: bold;
    font-style: italic;
    color: var(--fg);
  }
}

input:focus-visible {
  outline: none;

}

input[type="checkbox"] {
  border-radius: 5px;

  &:after,
  &:focus:after {
    background-size: 1.2em;
    background-position: center;
    opacity: 0;
  }

  &:checked:after {
    background: url('data:image/svg+xml; utf8, <svg width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M13.7071,4.29289 C14.0976,4.68342 14.0976,5.31658 13.7071,5.70711 L7.70711,11.7071 C7.31658,12.0976 6.68342,12.0976 6.29289,11.7071 L3.29289,8.70711 C2.90237,8.31658 2.90237,7.68342 3.29289,7.29289 C3.68342,6.90237 4.31658,6.90237 4.70711,7.29289 L7,9.58579 L12.2929,4.29289 C12.6834,3.90237 13.3166,3.90237 13.7071,4.29289 Z"  style="fill: hsl(222,20%,40%);"/></svg>');
    background-size: 1.2em;
    background-position: center;
    opacity: 1;
  }

  .dark &:checked:after {
    background-image: url('data:image/svg+xml; utf8, <svg width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M13.7071,4.29289 C14.0976,4.68342 14.0976,5.31658 13.7071,5.70711 L7.70711,11.7071 C7.31658,12.0976 6.68342,12.0976 6.29289,11.7071 L3.29289,8.70711 C2.90237,8.31658 2.90237,7.68342 3.29289,7.29289 C3.68342,6.90237 4.31658,6.90237 4.70711,7.29289 L7,9.58579 L12.2929,4.29289 C12.6834,3.90237 13.3166,3.90237 13.7071,4.29289 Z"  style="fill: hsl(222,20%,95%);"/></svg>');
  }
}





.range {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 1em;

  label {
    margin: 0;
    min-width: max-content;
    text-transform: none;
    font-size: 0.75em;
  }

  &:first-child {
    text-align: right;
  }

  &:last-child {
    text-align: left;
  }
}

input[type="range"] {
  --pad: 4px;
  appearance: none;
  width: calc(100% - 1em);
  height: calc(1em + var(--pad) * 2);
  border: none;
  outline: none;
  padding: 0 var(--pad);
  margin-inline: 0.5em;
  border-radius: 1em;

  &,
  &* {
    transition: all 0.5s ease;
  }
}

@mixin range-thumb {
  appearance: none;
  width: 1em;
  height: 1em;
  border: none;
  outline: none;
  background: var(--fg);
  transform: translate3d(0, 0, 0);
}

@mixin range-track {
  background: linear-gradient(90deg, var(--tint) -25%, var(--tint2) 125%);
  display: block;
  width: calc(100% + var(--pad) * 2);
  height: calc(1em + var(--pad) * 2);
  border-radius: 1em;
  margin-inline: calc(var(--pad) * -1);
  transition: opacity 0.5s ease-in;
  opacity: 0;
  will-change: opacity;
}

input[type="range"]::-webkit-slider-thumb {
  @include range-thumb;
}

input[type="range"]::-moz-range-thumb {
  @include range-thumb;
}

input[type="range"]::-ms-thumb {
  @include range-thumb;
}

input[type="range"]::-webkit-slider-runnable-track {
  @include range-track;
}

input[type="range"]::-moz-range-track {
  @include range-track;
}

input[type="range"]::-ms-track {
  @include range-track;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  transition: opacity 0.5s ease-out;
  opacity: 1;
}

input[type="range"]:focus::-moz-range-track {
  transition: opacity 0.5s ease-out;
  opacity: 1;
}

input[type="range"]:focus::-ms-track {
  transition: opacity 0.5s ease-out;
  opacity: 1;
}

input[type="color"] {
  appearance: none;
  max-width: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5em;
  padding: 5px;
  border-radius: 10em;
}

input[type="color"]::-moz-color-swatch,
input[type="color"]::-webkit-color-swatch {
  border: inherit;
  border-radius: inherit;
  display: block;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.5em;
}

button {
  display: flex;
  align-items: center;
  gap: 0.5em;
  background: hsl(var(--h), var(--s), 90%);
  text-transform: uppercase;
  width: auto;
  padding-inline: 2em;
  box-shadow: inset 0 0 0 1px transparent;
  transition: all 0.3s ease;

  .dark & {
    background: hsl(var(--h), var(--s), 15%);
  }

  &:hover {
    opacity: 0.8;
    box-shadow: inset 0 0 0 1px var(--fg);
  }

  &[type="submit"] {
    --svg: var(--bg);
    background: var(--fg);
    color: var(--bg);
    font-weight: 600;
  }

}

svg {
  fill: var(--svg);
  height: 1.5em;
  width: auto;
}

.group {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin-block: 1em;
  width: max-content;

  input+label {
    display: inline-block;
    margin-block: 0;
    padding: 0.5em 1em;
    margin-inline-end: 2em;
  }
}

@media screen and (min-width: 600px) {
  .group {
    grid-template-columns: repeat(2, auto 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .group {
    grid-template-columns: repeat(4, auto 1fr);
  }
}

label {
  display: block;
  width: max-content;
  font-size: 0.875rem;
  text-transform: uppercase;
  margin-inline-start: 0;
  margin-block-end: 0.5em;
  color: var(--labels);
  transition: all 0.5s ease;
}

.field:focus-within label {
  color: var(--fg);
}

.field {
  margin-block: 2.5em;
}

.field:nth-of-type(1) {
  margin-block-start: 1em;
}

.field:nth-last-of-type(1) {
  margin-block-end: 1em;
}

fieldset {
  min-width: 100%;
  width: 100%;
  border: 1px solid var(--bor);
  border-radius: 5px;
  padding: var(--pads);
  margin-block: var(--pads);
}

legend {
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0 2em;
  transform: translateX(-1em);
}

form {
  min-width: 100%;
  width: 100%;
  background-color: var(--bg);
  color: var(--fg);
  padding: 0px;
  border-radius: 8px;
}

form {
  --bor: hsl(var(--h), var(--s), 85%);
  --bg: var(--light);
  --fg: var(--dark);
  --labels: hsl(var(--h), var(--s), 40%);
  --b: hsla(var(--h), var(--s), 100%, 1);
  --svg: var(--fg);
}

form.dark {
  --bor: hsl(var(--h), var(--s), 35%);
  --bg: var(--dark);
  --fg: var(--light);
  --labels: hsl(var(--h), var(--s), 65%);
  --b: hsla(var(--h), var(--s), 12%, 1);

  --tint: hsl(260, 85%, 60%);
  --tint2: hsl(300, 85%, 60%);
}



.input-style {
  margin: 60px 0px;

  @media (max-width: $layout-breakpoint-small) {
    margin: 30px 0px;
  }
}

.row.input-style .col-12 {
  @media (max-width: $layout-breakpoint-small) {
    margin-bottom: 35px;
  }
}

.input-style .col-6 {
  padding: 0px 10px !important;
}

.height-aria {
  min-height: 150px !important;

  @media (max-width: $layout-breakpoint-small) {
    min-height: 100px !important;
    border: 1px solid #a19f9f;
  }
}

.row.input-style .submit-btn {
  width: 280px;
  text-transform: capitalize !important;
  text-align: center !important;
  background-color: #c9b081 !important;
  font-weight: 400 !important;
  display: block !important;
  margin-left: 10px !important;

  @media (max-width: $layout-breakpoint-medium) {
    width: 230px;
  }

  @media (max-width: $layout-breakpoint-small) {
    width: 200px;
    height: auto;
    border: 1px solid #fff !important;
    margin-left: 0px !important;
  }
}


.mt-5-form {
  margin-top: 60px;

  @media (max-width: $layout-breakpoint-small) {
    margin-top: 40px;
  }
}

.form-header.content {
  padding: 0px 10px !important;

  @media (max-width: $layout-breakpoint-small) {
    padding: 0px 0px !important;
  }
}

//Hamberger Menu 

#menuToggle {
  display: block;
  position: absolute;
  top: 30px;
  // left: 350px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a {
  text-decoration: none;
  color: #232323;

  transition: color 0.3s ease;
}

#menuToggle a:hover {
  color: tomato;
}


#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;

  cursor: pointer;

  opacity: 0;
  /* hide this */
  z-index: 2;
  /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: #cdcdcd;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked~span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked~span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked~span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu {
  position: fixed;
  width: 330px;
  margin: -55px 0 0 -295px;
  padding: 30px;
  padding-top: 10px;
  // z-index: 99999999999;
  background: #fff;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  height: 100vh;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
}

#menu li {
  padding: 10px 0;
  font-size: 22px;
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked~ul {
  transform: none;
}

.header-logo img {
  width: 37px;
  margin-top: 18px;
  margin-left: 25px;

}

#menu .header-logo img {
  margin-top: 0px;
  margin-left: 0px;
}

.header span {
  font-size: 1.8rem !important;
}

.form-header .header-title {


  @media (max-width: $layout-breakpoint-xsmall) {
    font-size: 2.4rem;
  }
}


.flex {
  display: flex;
  align-items: center;
}

.header-call-to-action {
  background-color: #d1ba8e;
  width: 36px;
  padding: 6px;
  border-radius: 50%;
  margin-top: 20px;
  margin-left: 85px;
}

li.bottom-menu {
  margin-top: 39px;
  border-top: 1px solid #ede9e9;
  padding-top: 33px !important;

}

.bottom-menu-item a {
  font-size: 16px;
  font-weight: 400;
}

.bottom-menu-item {

  padding: 0px 0 !important;
}


.plr-3 {
  padding: 0px 10px !important;
}

.display-none {
  display: none !important;
}

.error-waper {
  margin-top: 10px;
  font-size: 12px;
  color: red;
}

.submit-message-wrapper {
  margin-top: 10px;
  font-size: 12px;
  color: rgb(12, 12, 12);
}

.m-header-top {
  margin-top: 20px;
}

.font-small {
  font-size: 16px !important;
}